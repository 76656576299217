// lib/metadata.ts

import type { Metadata } from "next";

interface MetadataProps {
  title: string;
  description: string;
  keywords?: string[];
  openGraph?: {
    title?: string;
    description?: string;
    images?: string[];
    url?: string;
  };
  twitter?: {
    card?: "summary" | "summary_large_image" | "app" | "player";
    title?: string;
    description?: string;
    images?: string[];
  };
  canonical?: string;
}

export function generateMetadata({
  title,
  description,
  keywords,
  openGraph,
  twitter,
  canonical,
}: MetadataProps): Metadata {
  return {
    title,
    description,
    keywords: keywords?.join(", "),
    openGraph: {
      title: openGraph?.title || title,
      description: openGraph?.description || description,
      images: openGraph?.images,
      url: openGraph?.url,
    },
    twitter: {
      card: twitter?.card || "summary_large_image",
      title: twitter?.title || openGraph?.title || title,
      description: twitter?.description || openGraph?.description || description,
      images: twitter?.images || openGraph?.images,
    },
    alternates: {
      canonical: canonical,
    },
  };
}